import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppRouter from "./config/appRouter";
import store from "./config/store";

function App() {
  const [karachiHour, setKarachiHour] = useState(null);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      var scrollPosition = window.scrollY;
      if (scrollPosition === 0) {
        document.body.classList.add("scroll-border");
      } else {
        document.body.classList.remove("scroll-border");
      }
    });
  }, []);

  useEffect(() => {
    function getKarachiTime() {
      const now = new Date();
      const options = { timeZone: "Asia/Karachi", hour: "2-digit", hour12: false };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedHour = parseInt(formatter.format(now), 10);
      setKarachiHour(formattedHour);
    }

    getKarachiTime();
    const interval = setInterval(getKarachiTime, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <Provider store={store}>
      {/* {karachiHour !== null && karachiHour < 18 ? (
        <div className="appbar_area">
          Enjoy a Delicious <span style={{ fontWeight: "600", margin: "0 5px" }}>20% Off</span> on Your Order Before Iftar
        </div>
      ) : (
        <div className="appbar_area">
          Enjoy Free Delivery on Orders of <span style={{ fontWeight: "600", margin: "0 5px" }}>Rs 4,000</span> or More!
        </div>
      )} */}
      {
        <div className="appbar_area">
        Enjoy Free Delivery on Orders of <span style={{ fontWeight: "600", margin: "0 5px" }}>Rs 4,000</span> or More!
      </div>
      }
      <AppRouter />
      <ToastContainer />
    </Provider>
  );
}

export default App;
